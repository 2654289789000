import { Link } from 'react-router-dom';
import { Button } from 'antd';
import TelePilotLogoBlack from '../../assets/tp_logo_final_black.svg';

function HomePage() {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="flex flex-col items-center">
        <div>
          <img src={TelePilotLogoBlack} alt="TelePilot AI" className="w-12 h-full mr-2" />
          <h1 className="text-4xl font-bold mb-8">Welcome to TelePilot AI</h1>
        </div>
        {/* <p className="text-lg text-center mb-12">
          Your App is a powerful platform that helps you provide exceptional customer service
          with AI-driven support solutions. Log in or sign up to get started.
        </p> */}
        <div className="space-y-4">
          <Link to="/signin">
            <Button type="primary" size="large" className="w-64 m-2">
              Sign In
            </Button>
          </Link>
          <Link to="/signup">
            <Button size="large" className="w-64 m-2">
              Sign Up
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
