/* eslint-disable consistent-return */
import './App.css';
import {
  Route, Routes, useSearchParams,
} from 'react-router-dom';
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { message } from 'antd';
import SignUpPage from './components/pages/SignUpPage';
import SignInPage from './components/pages/SignInPage';
import ConfirmEmailPage from './components/pages/ConfirmEmailPage';
import HomePage from './components/pages/HomePage';
import { appFrontendURL, cookieDomain } from './config';
import VerifyEmailPage from './components/pages/VerifyEmailPage';
import ForgotPasswordPage from './components/pages/ForgotPaswordPage';
import ResetPasswordPage from './components/pages/ResetPasswordPage';

function App() {
  const [searchParams] = useSearchParams();
  const state = searchParams.get('state');
  const success = searchParams.get('success');
  const messageText = searchParams.get('message');
  const token = searchParams.get('token');

  const urlMessage = () => {
    if (success === 'true' && messageText && token) {
      message.success(messageText);
      Cookies.set('accessToken', token, { domain: cookieDomain, path: '/' });
      if (state && state === 'signup') {
        window.location.href = `${appFrontendURL}/welcome-company`;
      } else {
        window.location.href = appFrontendURL;
      }
    } else if (success === 'false' && messageText) {
      message.error(messageText);
    }
  };
  useEffect(() => {
    const accessToken = Cookies.get('accessToken');
    if (accessToken) {
      return window.location.replace(appFrontendURL);
    }
    urlMessage();
  }, []);

  return (
    <div className="App font-nunito">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/signin" element={<SignInPage />} />
        <Route path="/confirm-mail" element={<ConfirmEmailPage />} />
        <Route path="/verify-email" element={<VerifyEmailPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        {/* <Route element={<NotFoundRoute />} /> */}
      </Routes>
    </div>
  );
}

export default App;
