/* eslint-disable consistent-return */
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Result, message } from 'antd';
import Cookies from 'js-cookie';
import {
  ssoBackendURL, cookieDomain, appFrontendURL,
} from '../../config';

function VerifyEmailPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [emailVerified, setEmailVerified] = useState(false);
  const email = searchParams.get('email');
  const verificationToken = searchParams.get('verificationToken');

  const verifyEmail = async (userEmail, token) => {
    try {
      if (!emailVerified) {
        const verifyEmailRes = await fetch(`${ssoBackendURL}/auth/verify`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email: userEmail, verificationToken: token }),
        }).then((res) => res.json());
        if (verifyEmailRes.success) {
          setEmailVerified(true);
          message.success(verifyEmailRes.message);
          const newAccessToken = verifyEmailRes.token;
          Cookies.set('accessToken', newAccessToken, { domain: cookieDomain, path: '/' });
          // google signup conversion tracker
          window.gtag_report_conversion();
          return setTimeout(() => {
            window.location.href = `${appFrontendURL}/welcome-company`;
          }, 3000);
          // navigate(appFrontendURL, { replace: true });
        }
        message.error(verifyEmailRes.message);
        return setTimeout(() => {
          navigate('/', { replace: true });
        }, 3000);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    if ((email && verificationToken && !emailVerified)) {
      verifyEmail(email, verificationToken);
    }
  }, []);

  return (
    <div className="flex justify-center items-center h-screen">
      {emailVerified
        ? (
          <div>
            <Result
              status="success"
              title="Congratulations. Email Verified."
              subTitle="Welcome to TelePilot AI!"
              extra={[
                <p>
                  Redirecting you to app ...
                </p>,
              ]}
            />
          </div>
        )
        : <h1 className="text-3xl font-bold">Verifying Email...</h1>}
    </div>
  );
}

export default VerifyEmailPage;
