import { useState } from 'react';
import {
  Form, Input, Button, message, Divider,
} from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { ArrowLeftOutlined } from '@ant-design/icons';
// import TelePilotLogoBlack from '../../assets/tp_logo_final_black.svg';
import TPLogoWithNameBlack from '../../assets/logo-with-name-black.svg';
import { ssoBackendURL, cookieDomain, ssoFrontendURL } from '../../config';
import GoogleLogo from '../../assets/Google__G__Logo.svg';

function SignUpPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [agreeTerms] = useState(true);
  const [step, setStep] = useState(1);
  const [tempData, setTempData] = useState({});

  const onNext = (values) => {
    setTempData(values);
    setStep(2);
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const {
        first_name, last_name, email,
      } = tempData;
      // const data = { ...tempData, password: values.password };
      Cookies.set('userEmail', email, { domain: cookieDomain, path: '/' });
      const signUpRes = await fetch(`${ssoBackendURL}/auth/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          firstName: first_name, lastName: last_name, email, password: values.password, referrer: ssoFrontendURL, source: 'regular',
        }),
      });
      const signUpResJson = await signUpRes.json();
      setLoading(false);
      if (signUpResJson.success) {
        navigate('/confirm-mail');
      }
      return message.info(signUpResJson.message);
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };

  const googleSignup = async () => {
    try {
      window.gtag_report_conversion();
      window.location.href = `${ssoBackendURL}/auth/google/signup?referrer=${ssoFrontendURL}&source=regular`;
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <>
      {/* DESKTOP VIEW */}
      <div className="items-center h-screen justify-center hidden md:flex">

        {/* Left Panel */}
        <div className="flex flex-col justify-around w-1/2 p-8 h-full bg-brand">
          <div className="flex flex-col h-5/6 justify-center">

            <h2 className="text-2xl mb-4 font-bold">Welcome!</h2>
            <p className="text-black text-lg mb-6">
              {`With TelePilot AI, you can reduce the need for a large support team and ensure consistent
            and personalized customer experiences.`}
            </p>
          </div>
          <div className="flex justify-center h-20 items-center m-4 ">
            <Link to="https://www.telepilot.ai">
              <img src={TPLogoWithNameBlack} alt="TelePilot AI logo" className="w-40 h-full mr-2" />
            </Link>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center w-1/2 p-8 bg-white h-full">
          <div className="flex justify-between w-1/2">
            <div className="flex items-center w-1/3">
              <ArrowLeftOutlined className={`${step === 1 ? 'hidden' : 'flex mt-2 cursor-pointer'}`} onClick={() => setStep(1)} />
            </div>
            <div className="w-2/3 text-start">
              <h2 className="text-2xl mb-4">Sign Up</h2>
            </div>
          </div>
          <Form className="w-1/2 flex flex-col text-black" name="signup-form" onFinish={step === 1 ? onNext : onFinish}>
            {step === 1 && (
            <>
              <Form.Item
                name="first_name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your first name',
                  },
                  {
                    pattern: /^[a-zA-Z]+$/,
                    message: 'Please enter a valid first name',
                  },
                ]}
              >
                <Input placeholder="First Name" />
              </Form.Item>
              <Form.Item
                name="last_name"
                rules={[
                  {
                    pattern: /^[a-zA-Z\s]+$/,
                    message: 'Please enter a valid last name',
                  },
                ]}
              >
                <Input placeholder="Last Name" />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: 'Please enter your email' },
                  { type: 'email', message: 'Please enter a valid email' },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>

              <Form.Item className="mb-0 pointer-events-none opacity-30">
                <Button className="mt-3" block type="primary" htmlType="submit">
                  Continue
                </Button>
              </Form.Item>
            </>
            )}
            {step === 2 && (
            <>
              <span className="mb-2 text-start">Setup a password for your account.</span>
              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please enter your password' }]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                dependencies={['password']}
                rules={[
                  { required: true, message: 'Please confirm your password' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Passwords do not match'));
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Confirm Password" />
              </Form.Item>
              {/* <div className="mb-4 flex">
                <label htmlFor="agreeTermsCheckbox">
                  <input
                    id="agreeTermsCheckbox"
                    type="checkbox"
                    checked={agreeTerms}
                    onChange={() => setAgreeTerms(!agreeTerms)}
                  />
                  I have read and agree to the
                  {' '}
                  <a href="https://www.telepilot.ai/terms" rel="noreferrer" target="_blank">Terms of Use</a>
                </label>
              </div> */}
              <Form.Item className="mb-0 pointer-events-none opacity-30">
                <Button type="primary" htmlType="submit" block loading={loading} disabled={!agreeTerms}>
                  Sign Up
                </Button>
              </Form.Item>
            </>
            )}
            <Divider orientation="center">or</Divider>
            <div className="mb-8 no-underline pointer-events-none opacity-30">
              {/* <a href={`${ssoBackendURL}/auth/google/signup`}> */}
              <Button className="flex justify-center items-center min-w-fit font-bold w-full no-underline" onClick={googleSignup}>
                <img src={GoogleLogo} alt="Google logo" className="w-4 h-4 mr-2" />
                Sign Up with Google
              </Button>
              {/* </a> */}
            </div>

            <div className="mb-12 flex text-xs text-gray-500">
              <label htmlFor="agreeTermsCheckbox">
                By continuing you accept that you have read and agree to the
                {' '}
                <a href="https://www.telepilot.ai/terms" rel="noreferrer" target="_blank">Terms of use.</a>
              </label>
            </div>
            <p>
              Currently offering new signups via invite only
              <br />
              <span>
                Contact
                <a href="mailto:support@telepilot.ai"> support@telepilot.ai </a>
                to request one
              </span>
            </p>
            <div className="pointer-events-none opacity-30">
              Already have an account?
              {' '}
              <Link to="/signin">Sign In</Link>
            </div>

          </Form>
        </div>
      </div>

      {/* MOBILE VIEW */}
      <div className="flex flex-col items-center justify-center w-full bg-white mt-12 h-full md:hidden">
        <div className="flex justify-between w-5/6">
          <div className="flex items-center w-1/3">
            <ArrowLeftOutlined className={`${step === 1 ? 'hidden' : 'flex mt-2 cursor-pointer'}`} onClick={() => setStep(1)} />
          </div>
          <div className="w-4/6 text-start">
            <h2 className="text-2xl mb-4">Sign Up</h2>
          </div>
        </div>
        <Form className="w-full p-4 px-8 flex flex-col text-black" name="signup-form" onFinish={step === 1 ? onNext : onFinish}>
          {step === 1 && (
            <>
              <Form.Item
                name="first_name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your first name',
                  },
                  {
                    pattern: /^[a-zA-Z]+$/,
                    message: 'Please enter a valid first name',
                  },
                ]}
              >
                <Input placeholder="First Name" />
              </Form.Item>
              <Form.Item
                name="last_name"
                rules={[
                  {
                    pattern: /^[a-zA-Z\s]+$/,
                    message: 'Please enter a valid last name',
                  },
                ]}
              >
                <Input placeholder="Last Name" />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: 'Please enter your email' },
                  { type: 'email', message: 'Please enter a valid email' },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>

              <Form.Item className="mb-0 pointer-events-none opacity-30">
                <Button className="mt-3" block type="primary" htmlType="submit">
                  Continue
                </Button>
              </Form.Item>
            </>
          )}
          {step === 2 && (
            <>
              <span className="mb-2 text-start">Setup a password for your account.</span>
              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please enter your password' }]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                dependencies={['password']}
                rules={[
                  { required: true, message: 'Please confirm your password' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Passwords do not match'));
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Confirm Password" />
              </Form.Item>
              {/* <div className="mb-4 flex">
                <label htmlFor="agreeTermsCheckbox">
                  <input
                    id="agreeTermsCheckbox"
                    type="checkbox"
                    checked={agreeTerms}
                    onChange={() => setAgreeTerms(!agreeTerms)}
                  />
                  I have read and agree to the
                  {' '}
                  <a href="https://www.telepilot.ai/terms" rel="noreferrer" target="_blank">Terms of Use</a>
                </label>
              </div> */}
              <Form.Item>
                <Button type="primary" htmlType="submit" block loading={loading} disabled={!agreeTerms}>
                  Sign Up
                </Button>
              </Form.Item>
            </>
          )}
          <Divider orientation="center">or</Divider>
          <div className="mb-8 no-underline pointer-events-none opacity-30">
            {/* <a href={`${ssoBackendURL}/auth/google/signup`}> */}
            <Button className="flex justify-center items-center min-w-fit font-bold w-full no-underline" onClick={googleSignup}>
              <img src={GoogleLogo} alt="Google logo" className="w-4 h-4 mr-2" />
              Sign Up with Google
            </Button>
            {/* </a> */}
          </div>
          <div className="mb-12 mx-2 flex text-xs text-gray-500 self-center">
            <label htmlFor="agreeTermsCheckbox">
              By continuing you accept that you have read and agree to the
              {' '}
              <a href="https://www.telepilot.ai/terms" rel="noreferrer" target="_blank">Terms of use.</a>
            </label>
          </div>
          <p>
            Currently offering new signups via invite only
            <br />
            <span>
              Contact
              <a href="mailto:support@telepilot.ai"> support@telepilot.ai </a>
              to request one
            </span>
          </p>
          <div className="mb-8 self-center pointer-events-none opacity-30">
            Already have an account?
            {' '}
            <Link to="/signin">Sign In</Link>
          </div>
        </Form>

        <div className="flex justify-center h-40 items-center bg-brand w-full p-0">
          <Link to="https://www.telepilot.ai">
            <img src={TPLogoWithNameBlack} alt="TelePilot AI logo" className="w-40 h-full mr-2" />
          </Link>
        </div>
      </div>
    </>
  );
}

export default SignUpPage;
