import { useEffect, useState } from 'react';
import {
  Form, Input, Button, message,
} from 'antd';
import { useSearchParams, useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import {
  appFrontendURL, cookieDomain, ssoBackendURL, ssoFrontendURL,
} from '../../config';

function ResetPasswordPage() {
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');

  const navigate = useNavigate();

  const resetPassword = async (userEmail, password, resetToken) => {
    try {
      setLoading(true);
      const passwordResetRes = await fetch(`${ssoBackendURL}/auth/reset-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: userEmail, password, resetToken, referrer: ssoFrontendURL,
        }),
      });
      const passwordResetData = await passwordResetRes.json();
      if (!passwordResetRes.ok) {
        setLoading(false);
        return message.error(passwordResetData.message);
      }
      setLoading(false);
      message.success('Your password has been reset successfully');
      Cookies.set('userEmail', userEmail, { domain: cookieDomain, path: '/' });
      Cookies.set('accessToken', passwordResetData.token, { domain: cookieDomain, path: '/' });
      return setTimeout(() => { window.location.href = appFrontendURL; }, 2000);
    } catch (error) {
      setLoading(false);
      return message.error('Failed to reset your password. Please request a new link and try again.');
    }
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const { password } = values;
      await resetPassword(email, password, token);
      setLoading(false);
      message.success('Your password has been reset successfully');
    } catch (error) {
      setLoading(false);
      message.error('Failed to reset your password. Please try again later.');
    }
  };

  useEffect(() => {
    const paramToken = searchParams.get('token');
    if (!paramToken) {
      message.error('Invalid reset password link');
      setTimeout(() => navigate('/signin'), 3000);
    }
    try {
      const decodedToken = jwtDecode(paramToken);
      if (!decodedToken.email) {
        message.error('Invalid reset password link');
        setTimeout(() => navigate('/signin'), 3000);
      }
      if (decodedToken.exp < Date.now() / 1000) {
        message.error('Reset password link has expired');
        setTimeout(() => navigate('/signin'), 3000);
      }
      setEmail(decodedToken.email);
      setToken(paramToken);
    } catch (error) {
      message.error('Invalid reset password link');
      setTimeout(() => navigate('/signin'), 3000);
    }
  }, []);

  return (
    <div className="flex items-center h-screen justify-center">
      <div className="flex flex-col items-center w-1/2 p-8 bg-gray-100">
        <h2 className="text-2xl mb-4">Reset Password</h2>
        <div className="">
          <p className="text-base m-2">
            Enter your new password below to reset your account password.
          </p>
          <p className="text-sm m-0 mb-4">
            {'If you need any assistance, please contact our support team at '}
            <a className="underline cursor-pointer" href="mailto:support@telepilot.ai">support@telepilot.ai</a>
          </p>
        </div>
        <div className="flex">
          <span className="text-lg mt-2">
            email:
          </span>
          <p className="text-lg m-2 font-bold">
            {`${email}`}
          </p>
        </div>
        <Form className="w-1/2 flex flex-col" name="reset-password-form" onFinish={onFinish}>
          {/* <Form.Item
            name="email"
            initialValue={email}
            rules={[{ required: true, message: 'Email is required' }]}
          >
            <Input disabled />
          </Form.Item> */}
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please enter your new password' }]}
          >
            <Input.Password placeholder="New Password" />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            dependencies={['password']}
            rules={[
              { required: true, message: 'Please confirm your new password' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Passwords do not match'));
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm New Password" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block loading={loading}>
              Reset Password
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default ResetPasswordPage;
