/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useEffect } from 'react';
import { CheckCircleTwoTone } from '@ant-design/icons';
import {
  Form, Input, Button, message,
} from 'antd';
import { Link } from 'react-router-dom';
import { ssoBackendURL } from '../../config';

function ForgotPasswordPage() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [timer, setTimer] = useState(30);

  useEffect(() => {
    if (emailSent) {
      const interval = setInterval(() => {
        if (timer > 0) {
          setTimer((prevTimer) => prevTimer - 1);
        }
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [timer, emailSent]);

  const sendPasswordResetMail = async () => {
    try {
      setTimer(30);
      setLoading(true);
      const response = await fetch(`${ssoBackendURL}/auth/forgot-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      if (!response.ok) {
        setLoading(false);
        setEmailSent(false);
        return message.error(data.message);
      }
      setLoading(false);
      setEmailSent(true);
      return message.success(`Password reset link has been sent to ${email}`);
    } catch (error) {
      setLoading(false);
      setEmailSent(false);
      return message.error('Failed to send password reset link. Please try again later.');
    }
  };

  const onFinish = async () => {
    try {
      await sendPasswordResetMail();
    } catch (error) {
      setLoading(false);
      message.error('Failed to send password reset link. Please try again later.');
    }
  };

  return (
    <div className="flex items-center h-screen justify-center ">
      <div className="flex flex-col items-center w-2/3 h-fit p-8 bg-gray-100 rounded-lg justify-around">
        <h2 className="text-2xl">Forgot Password</h2>
        <div className="">
          <p className="text-base mb-4 w-4/5 m-auto">
            {`Enter the email address associated with your account below. 
            We will send you a link to reset your password.`}
          </p>
          <p className="text-sm mb-4">
            {'If you need any assistance, please contact our support team at '}
            <a className="underline cursor-pointer" href="mailto:support@telepilot.ai">support@telepilot.ai</a>
          </p>
        </div>
        <Form className="w-full flex flex-col m-4 items-center" name="forgot-password-form" onFinish={onFinish}>
          <Form.Item
            className="w-full"
            name="email"
            rules={[
              { required: true, message: 'Please enter your email' },
              { type: 'email', message: 'Please enter a valid email' },
            ]}
          >
            <Input
              className="w-full md:3/5 lg:w-1/2"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailSent(false);
              }}
            />
          </Form.Item>
          <Form.Item>
            <Button className="w-fit" type="primary" htmlType="submit" loading={loading} disabled={emailSent}>
              Send Reset Link
            </Button>
          </Form.Item>
          <div>
            Remembered your password?
            {' '}
            <Link to="/signin">Sign In</Link>
          </div>
        </Form>
        {emailSent && timer !== 0 && (
          <div className="flex items-center m-4">
            <CheckCircleTwoTone twoToneColor="#52c41a" />
            <p className="text-xs ml-2">Mail was sent to above email address with a reset link.</p>
          </div>
        )}
        {
                emailSent && timer === 0 && (
                <div className="text-xs flex items-center m-4">
                  <p>Didn&apos;t recieve email? Check spam or</p>
                  <span className="text-xs m-2 cursor-pointer underline" onClick={sendPasswordResetMail} disabled>
                    Send again
                  </span>
                </div>
                )
            }
      </div>
    </div>
  );
}

export default ForgotPasswordPage;
