import { useState } from 'react';
import {
  Form, Input, Button, message, Divider,
  Tooltip,
} from 'antd';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import {
  ssoBackendURL, appFrontendURL, cookieDomain, ssoFrontendURL,
} from '../../config';
import TPLogoWithNameBlack from '../../assets/logo-with-name-black.svg';
import GoogleLogo from '../../assets/Google__G__Logo.svg';

function SignIn() {
  const [searchParams] = useSearchParams();

  const redirectTo = searchParams.get('redirect');

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  // eslint-disable-next-line consistent-return
  const onFinish = async (values) => {
    try {
      const { email, password } = values;
      setLoading(true);

      // save user email for later reference
      Cookies.set('userEmail', email, { domain: cookieDomain, path: '/' });

      // attempt signin with given email and password
      const signInRes = await fetch(`${ssoBackendURL}/auth/signin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email, password, referrer: ssoFrontendURL, source: 'regular',
        }),
      });
      // if internal server error
      if (signInRes.status === 500) {
        setLoading(false);
        return message.error('Something went wrong, please try signing again later after some time.');
      }

      // if signin fails, show error message
      if (signInRes.status !== 200) {
        setLoading(false);
        return message.error('Invalid email or password');
      }

      const signInResJson = await signInRes.json();

      // check if email is verified for the user, move to confirm-mail page if not
      if (!signInResJson.emailVerified) {
        return navigate('/confirm-mail');
      }

      const { token } = signInResJson;
      Cookies.set('accessToken', token, { domain: cookieDomain, path: '/' });

      setLoading(false);
      message.success(signInResJson.message);

      if (redirectTo) {
        return window.location.replace(redirectTo);
      }
      return window.location.replace(appFrontendURL);
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };

  return (
    <>
      {/* DESKTOP VIEW */}
      <div className="items-center h-screen justify-center hidden md:flex">
        <div className="flex flex-col justify-around w-1/2 p-8 h-full bg-brand">
          <div className="flex flex-col h-5/6 justify-center">
            <h2 className="text-2xl mb-4 font-bold">Welcome Back!</h2>
            <p className="text-lg mb-4">
              {`Log in to your TelePilot AI account and continue
           providing exceptional customer service with AI-driven support solutions.`}
            </p>
          </div>
          <div style={{ fontFamily: 'Nunito Light, sans-serif' }} className="flex justify-center h-20 items-center m-4 ">
            <Link to="https://www.telepilot.ai">
              <img src={TPLogoWithNameBlack} alt="TelePilot AI logo" className="w-40 h-full mr-2" />
            </Link>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center w-1/2 p-8 bg-gray-100 h-full">
          <div className="flex justify-center h-20 items-center">
            <h2 className="flex items-center text-2xl h-full">Sign In</h2>
          </div>
          <Form className="w-1/2 flex flex-col" name="signin-form" onFinish={onFinish}>
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Please enter your email' }]}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Please enter your password' }]}
              className="mb-0"
            >
              <Input.Password placeholder="Password" />
            </Form.Item>

            <div className="text-xs text-start mb-6 mt-2 pointer-events-none opacity-30">
              <Link to="/forgot-password">Forgot Password?</Link>
            </div>
            <Tooltip title="Manual sign-in disabled">
              <Form.Item className="mb-0 pointer-events-none opacity-30">
                <Button type="primary" htmlType="submit" block loading={loading}>
                  Sign In
                </Button>
              </Form.Item>
            </Tooltip>
            <Divider orientation="center">or</Divider>
            <div className="mb-12 pointer-events-none opacity-30">
              <a href={`${ssoBackendURL}/auth/google/signin?referrer=${ssoFrontendURL}&source='regular'`}>
                <Button className="flex justify-center items-center font-bold min-w-fit w-full">
                  <img src={GoogleLogo} alt="Google logo" className="w-4 h-4 mr-2" />
                  Sign In with Google
                </Button>
              </a>
            </div>
            <div className="pointer-events-none opacity-30">
              {'Don\'t have an account?'}
              {' '}
              <Link to="/signup">Sign Up</Link>
            </div>
          </Form>
        </div>
      </div>

      {/* MOBILE VIEW */}
      <div className="flex flex-col items-center justify-center w-full bg-white h-fit md:hidden">

        <h2 className="flex items-center text-2xl h-full">Sign In</h2>
        <Form className="w-full p-8 flex flex-col text-black" name="signin-form" onFinish={onFinish}>
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Please enter your email' }]}
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please enter your password' }]}
            className="mb-0"
          >
            <Input.Password placeholder="Password" />
          </Form.Item>

          <div className="text-xs text-start mb-6 mt-2 pointer-events-none opacity-30">
            <Link to="/forgot-password">Forgot Password?</Link>
          </div>
          {/* Additional form fields */}
          <Form.Item className="mb-0 pointer-events-none opacity-30">
            <Button type="primary" htmlType="submit" block loading={loading}>
              Sign In
            </Button>
          </Form.Item>
          <Divider orientation="center">or</Divider>
          <div className="mb-12 pointer-events-none opacity-30">
            <a href={`${ssoBackendURL}/auth/google/signin`}>
              <Button className="flex justify-center items-center font-bold min-w-fit w-full pointer-events-none">
                <img src={GoogleLogo} alt="Google logo" className="w-4 h-4 mr-2" />
                Sign In with Google
              </Button>
            </a>
          </div>
          <div className="pointer-events-none opacity-30">
            {'Don\'t have an account?'}
            {' '}
            <Link to="/signup">Sign Up</Link>
          </div>
        </Form>
        {/* </div> */}
        <div className="fixed bottom-0 justify-center h-40 items-center bg-brand w-full">
          <div className="flex justify-center h-20 items-center m-4 ">
            <Link to="https://www.telepilot.ai">
              <img src={TPLogoWithNameBlack} alt="TelePilot AI logo" className="w-40 h-full mr-2" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignIn;
