/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { Button, message } from 'antd';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { ssoBackendURL } from '../../config';

function ConfirmEmailPage() {
  const [email, setEmail] = useState('');
  const [timer, setTimer] = useState(60);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer((prevTimer) => prevTimer - 1);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  useEffect(() => {
    const userEmailFromCookie = Cookies.get('userEmail');
    setEmail(userEmailFromCookie);

    if (!userEmailFromCookie) {
      navigate('/signin');
    }

    // message.info(`A confirmation email has been sent to ${email}.
    //  Please click on the confirmation link in the email to verify your account.`);
  }, []);

  const handleContinue = () => {
    window.location.reload();
  };

  const handleEmailChange = () => {
    // Handle email change logic
    // console.log('Change email');
    navigate('/signup');
  };

  const handleResendConfirmation = async () => {
    try {
      const userEmailFromCookie = Cookies.get('userEmail');
      const resendConfirmationRes = await fetch(`${ssoBackendURL}/auth/resend-verification-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: userEmailFromCookie }),
      });
      if (!resendConfirmationRes.ok) {
        throw new Error('Something went wrong. Please try again.');
      }
      message.success(`Confirmation email sent again to ${email}. Please check spam folder if not received.`);
      setTimer(60); // Reset the timer
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen p-2">
      <h2 className="text-2xl">Confirm Your Email</h2>
      <p className="text-center text-lg">
        Thank you for signing up! We have sent a confirmation email to:
        <br />
        <p className="m-2"><strong>{email}</strong></p>
        <br />
        Please click on the confirmation link in the email to verify your account.
      </p>
      <Button type="primary" onClick={handleContinue}>
        {'I\'ve verified mail. Continue'}
      </Button>
      <div className="mt-4 text-sm">
        <p>
          {`Didn't receive the email? Please check your
           spam folder or wait for the email to arrive.`}
        </p>
        <p>
          If you entered the wrong email address, you can go back and change it.
        </p>
        <div className="flex justify-around">
          <Button className="mt-2" onClick={handleEmailChange} size="small">
            {'<- Go Back'}
          </Button>
          <Button className="mt-2" onClick={handleResendConfirmation} disabled={timer !== 0} size="small">
            {timer === 0 ? 'Resend Confirmation' : `Resend Confirmation (${timer})`}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmEmailPage;
